import { LoadingScreen } from "@the-source/front-core/components";
import { ReactNode, useContext } from "react";
import Login from "../../pages/login";
import { AuthContext, AuthContextType } from "../context/authContext";

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props): JSX.Element {
  const { isLoading, user } = useContext(AuthContext) as AuthContextType;

  if (!!user) return <>{children}</>;
  else if (isLoading) return <LoadingScreen />;
  else return <Login />;
}
