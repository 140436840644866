import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { RetryLink } from "@apollo/client/link/retry";
import { getAccessTokenPayloadSecurely } from "supertokens-auth-react/recipe/session";

const retryLink = new RetryLink({
  attempts: {
    retryIf: (error) => {
      return error.message == "Failed to fetch";
    },
  },
});

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_MEDIA_ADS_IMPORTS_API,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessTokenPayloadSecurely();
  return {
    headers: {
      ...headers,
      authorization: token.jwt ? `Bearer ${token.jwt}` : "",
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([retryLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
    },
  },
});

export default client;
