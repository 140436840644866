import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { AuthContext, AuthContextType } from "../../context/authContext";

import { LoadingButton } from "@mui/lab";
import { Alert, Stack, Typography } from "@mui/material";

import { FormProvider, RHFTextField } from "@the-source/front-core/components";
import localization from "../../localization";

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

export default function LoginForm(): JSX.Element {
  const {
    sendMagiclink,
    isMagiclinkSent,
    error: authError,
  } = useContext(AuthContext) as AuthContextType;
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
    },
  });

  const {
    formState: { errors: formErrors, isSubmitted },
    handleSubmit,
  } = methods;

  const errorContent = (
    <Typography noWrap>{`${localization.login.error.unknown} `}</Typography>
  );

  const onSubmit = async (userFormData: FormValuesProps): Promise<void> => {
    window.localStorage.setItem("email", userFormData.email);
    sendMagiclink(userFormData.email);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Alert
          sx={{
            visibility:
              formErrors.afterSubmit?.message || authError
                ? "visible"
                : "hidden",
          }}
          severity="error"
        >
          {authError == "unknown"
            ? errorContent
            : formErrors.afterSubmit?.message ??
              (authError && localization.login.error[authError])}
        </Alert>

        <RHFTextField name="email" label="Email address" />

        {isMagiclinkSent && <div>An email has been sent to your inbox</div>}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          disabled={isMagiclinkSent}
          variant="contained"
          loading={isSubmitted && !isMagiclinkSent}
        >
          {isMagiclinkSent ? "✅ Magic link sent" : "Login"}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
