import { Box, Container, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Page } from "@the-source/front-core/components";

import LoginForm from "../src/components/login/loginForm";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Login(): JSX.Element {
  return (
    <>
      <Page title="Login">
        <RootStyle>
          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Sign in to Creative Lift Asset Tagging
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter your details below.
                  </Typography>
                </Box>
              </Stack>

              <LoginForm />
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </>
  );
}
