export default function deleteCookie(name: string, path: string) {
  if (typeof window == "undefined") return;
  const cookie = document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
  if (cookie) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
