import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "@the-source/front-core/components";
import { ThemeProvider } from "@the-source/front-core/theme";
import { AppProps } from "next/app";
import SuperTokensReact from "supertokens-auth-react";
import authConfig from "../config/authConfig";
import client from "../src/apolloClient";
import { AuthProvider } from "../src/context/authContext";
import AuthGuard from "../src/guards/authGuard";
import { CampaignsProvider } from "../src/context/campaignsContext";

if (typeof window !== "undefined") {
  SuperTokensReact.init(authConfig());
}

const App = ({ Component, pageProps }: AppProps) => (
  <ThemeProvider themeMode={"light"}>
    <SnackbarProvider>
      <ApolloProvider client={client}>
        <AuthProvider>
          <AuthGuard>
            <CampaignsProvider>
              <Component {...pageProps} />
            </CampaignsProvider>
          </AuthGuard>
        </AuthProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </ThemeProvider>
);
export default App;
