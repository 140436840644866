import LocalizedStrings from "react-localization";

type Localization = {
  error: string;
  crash: string;
  login: {
    error: {
      misconfigured: string;
      expired_token: string;
      already_used_token: string;
      not_allowed: string;
      unknown: string;
    };
  };
  dataset_selection: {
    title: string;
    subtitles: {
      campaigns: string;
      datasets: string;
    };
    no_campaigns: string;
  };
  tagging: {
    confirm_with_selected_tags: string;
    confirm_without_tags: string;
    tags_title: string;
    informations: {
      title: string;
      campaign: string;
      dataset: string;
    };
  };
};

const localization = new LocalizedStrings<Localization>({
  "en-GB": require("/lang/en.json"),
});

export function formatPlural(key: string, count: number) {
  const safeCount = count || 0;
  const formattedAmount = formatAmount(safeCount);
  if (safeCount < 1) {
    const key_zero = localization.getString(`${key}.zero`);
    if (key_zero) return localization.formatString(key_zero, formattedAmount);
  }
  if (safeCount > 1) {
    const key_other = localization.getString(`${key}.other`);
    if (key_other) return localization.formatString(key_other, formattedAmount);
  }
  const key_one = localization.getString(`${key}.one`);
  if (key_one) return localization.formatString(key_one, formattedAmount);

  return localization.formatString(key, formattedAmount);
}

export function formatCurrency(amount: number, currencyCode: string) {
  const safeAmount = amount || 0;
  const format = new Intl.NumberFormat(localization.getLanguage(), {
    style: "currency",
    currency: currencyCode,
  });

  let ret = format.format(safeAmount);
  if (safeAmount < 1) {
    const parts = format.formatToParts(safeAmount * 100.0);
    ret = parts
      .map((p) => {
        if (p.type === "currency") {
          return `ct${p.value}`;
        }
        return p.value;
      })
      .join("");
  } else if (safeAmount >= 10000) {
    const parts = format.formatToParts(safeAmount);
    ret = parts
      .map((p) => {
        if (p.type === "fraction" || p.type === "decimal") {
          return null;
        }
        return p.value;
      })
      .join("");
  }
  return ret;
}

export function formatAmount(amount: number) {
  return new Intl.NumberFormat(localization.getLanguage()).format(amount);
}

export function getOptionalString(
  path: string,
  language?: string,
  omitWarning?: boolean
): string {
  const localizedString = localization.getString(path, language, omitWarning);
  return localizedString ? localizedString : path;
}

export default localization;
