import ThirdPartyPasswordlessReact from "supertokens-auth-react/recipe/thirdpartypasswordless";
import SessionReact from "supertokens-auth-react/recipe/session";
import Router from "next/router";
import { UrlObject } from "url";
import { WindowHandlerInterface } from "supertokens-website/utils/windowHandler/types";

const authConfig = () => {
  return {
    appInfo: {
      appName: "SaaS",
      websiteDomain: window.location.origin,
      apiDomain: process.env.NEXT_PUBLIC_USER_API as string,
      apiBasePath: "/users/auth",
      websiteBasePath: "",
    },
    recipeList: [
      ThirdPartyPasswordlessReact.init({
        contactMethod: "EMAIL",
      }),
      SessionReact.init(),
    ],
    // this is so that the SDK uses the next router for navigation
    windowHandler: (oI: WindowHandlerInterface) => {
      return {
        ...oI,
        location: {
          ...oI.location,
          setHref: (href: string | UrlObject) => {
            Router.push(href);
          },
        },
      };
    },
  };
};

export default authConfig;
