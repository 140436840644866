import React, { ReactNode, useEffect, useState } from "react";

import { useRouter } from "next/router";
import {
  doesSessionExist,
  getAccessTokenPayloadSecurely,
  signOut,
} from "supertokens-auth-react/recipe/session";
import {
  consumePasswordlessCode,
  createPasswordlessCode,
  getPasswordlessLinkCodeFromURL,
} from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { useUserLazyQuery } from "../graphql/graphql";
import deleteAuthCookies from "../utils/deleteAuthCookies";

export type AuthContextType = {
  sendMagiclink: (email: string) => void;
  logout: () => void;
  isLoading: boolean;
  isMagiclinkSent: boolean;
  error?: Error;
  user?: User;
};

type AuthProviderProps = {
  children: ReactNode;
};

export type User = {
  email: string;
  fullName: string;
};

type Error =
  | "already_used_token"
  | "expired_token"
  | "misconfigured"
  | "not_allowed"
  | "unknown";

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [fetchUser] = useUserLazyQuery();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const [isMagiclinkSent, setIsMagiclinkSent] = useState(false);
  const [user, setUser] = useState<User>();

  const router = useRouter();

  function fail(error: Error) {
    setError(error);
    setIsLoading(false);
  }

  async function hasSufficentRole(): Promise<boolean> {
    const jwtPayload = await getAccessTokenPayloadSecurely();
    return jwtPayload["st-role"].v.includes("asset_tagger");
  }

  function sendMagiclink(email: string) {
    createPasswordlessCode({ email })
      .then(({ status }) => {
        if (status == "OK") setIsMagiclinkSent(true);
        else setError("unknown");
      })
      .catch((_) => setError("unknown"));
  }

  async function handleMagiclink() {
    consumePasswordlessCode()
      .then(({ status: codeStatus }) => {
        switch (codeStatus) {
          case "OK":
            loadUser();
            break;
          case "EXPIRED_USER_INPUT_CODE_ERROR":
            fail("expired_token");
            break;
          case "RESTART_FLOW_ERROR":
            fail("already_used_token");
            break;
          default:
            fail("unknown");
            break;
        }
      })
      .catch((_) => fail("unknown"))
      .finally(() => router.replace("/", undefined));
  }

  async function loadUser() {
    if (await hasSufficentRole()) {
      fetchUser().then(({ data }) => {
        if (!data) {
          fail("unknown");
          deleteAuthCookies();
        } else setUser(data.user);
        setIsLoading(false);
      });
    } else {
      fail("not_allowed");
      deleteAuthCookies();
      setIsLoading(false);
    }
  }

  const logout = (): void => {
    signOut().then(() => {
      deleteAuthCookies();
      setUser(undefined);
    });
  };

  useEffect(() => {
    doesSessionExist().then((exist) => {
      if (exist) loadUser();
      else if (getPasswordlessLinkCodeFromURL().length > 0) {
        handleMagiclink();
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <Provider
      value={{
        sendMagiclink,
        logout,
        isLoading,
        isMagiclinkSent,
        error,
        user,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
