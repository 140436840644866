import {
  useContext,
  createContext,
  useMemo,
  useState,
  PropsWithChildren,
} from "react";
import { useTaggingCampaingsQuery } from "../graphql/graphql";
import { LinearProgress } from "@mui/material";
import ErrorAlert from "../components/errorAlert";
import { Dataset } from "../components/dataset_selection/datasetListItem";

type CampaignsProviderProps = { children: JSX.Element };

export type Campaign = {
  id: string;
  name: string;
  taggingDatasets: Dataset[];
};

type CampaignsContextType = {
  campaigns: Campaign[];
  currentCampaign?: Campaign;
  currentDataset?: Dataset;
  selectDataset: (datasetId: string) => void;
};

const CampaignsContext = createContext<CampaignsContextType | undefined>(
  undefined
);

const { Provider } = CampaignsContext;

const CampaignsProvider = ({ children }: CampaignsProviderProps) => {
  const { data, loading } = useTaggingCampaingsQuery({
    fetchPolicy: "network-only",
  });
  const [currentCampaign, setCurrentCampaign] = useState<Campaign>();
  const [currentDataset, setCurrentDataset] = useState<Dataset>();

  function selectDataset(datasetId: string) {
    const campaign = campaigns?.filter(({ taggingDatasets: datasets }) =>
      datasets.some(({ id }) => id == datasetId)
    )[0] as Campaign;
    setCurrentCampaign(campaign);
    const dataset = campaign.taggingDatasets.find(({ id }) => id == datasetId);
    setCurrentDataset(dataset);
  }

  const campaigns = useMemo(
    () =>
      data &&
      (data.taggingCampaings.filter(
        ({ __typename }) => __typename == "TaggingCampaign"
      ) as unknown as Campaign[]),
    [JSON.stringify(data)]
  );

  const Content = ({ children }: PropsWithChildren<any>) => {
    if (campaigns) return children;
    else if (loading) return <LinearProgress variant="indeterminate" />;
    else return <ErrorAlert />;
  };

  return (
    <Provider
      value={{
        campaigns: campaigns as Campaign[],
        currentCampaign,
        currentDataset,
        selectDataset,
      }}
    >
      <Content>{children}</Content>
    </Provider>
  );
};

const useCampaignsContext = () =>
  useContext(CampaignsContext) as CampaignsContextType;
export { CampaignsProvider, useCampaignsContext };
