import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AssignedAsset = {
  __typename: 'AssignedAsset';
  assetPath: Scalars['String'];
  assetType: Scalars['String'];
  availableTags: Array<Tag>;
  commonTagIds: Array<Scalars['String']>;
  groupId: Scalars['String'];
};

export type AssignedAssetNotFoundError = AssignedAsset | NotFoundError;

export enum ErrorCodes {
  ApiUnauthorized = 'API_UNAUTHORIZED',
  Internal = 'INTERNAL',
  RequestParseError = 'REQUEST_PARSE_ERROR'
}

export type Mutation = {
  __typename: 'Mutation';
  editProcessedAsset: ProcessedAsset;
  processAssignedAsset: AssignedAsset;
};


export type MutationEditProcessedAssetArgs = {
  groupId: Scalars['String'];
  tagIds: Array<Scalars['String']>;
  taggingDatasetId: Scalars['String'];
};


export type MutationProcessAssignedAssetArgs = {
  groupId: Scalars['String'];
  tagIds: Array<Scalars['String']>;
  taggingDatasetId: Scalars['String'];
};

export type NotFoundError = {
  __typename: 'NotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type ProcessedAsset = {
  __typename: 'ProcessedAsset';
  assetPath: Scalars['String'];
  assetType: Scalars['String'];
  availableTags: Array<Tag>;
  commonTagIds: Array<Scalars['String']>;
  groupId: Scalars['String'];
  processedAt: Scalars['DateTime'];
  tags: Array<Tag>;
};

export type Query = {
  __typename: 'Query';
  assignedAsset: AssignedAssetNotFoundError;
  message?: Maybe<Scalars['String']>;
  processedAssets: Array<ProcessedAsset>;
  taggingCampaings: Array<TaggingCampaignNotFoundError>;
  user: User;
};


export type QueryAssignedAssetArgs = {
  taggingDatasetId: Scalars['String'];
};


export type QueryProcessedAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  taggingDatasetId: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  AssetTagger = 'ASSET_TAGGER',
  Saas = 'SAAS',
  Service = 'SERVICE'
}

export type Tag = {
  __typename: 'Tag';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type TaggingCampaign = {
  __typename: 'TaggingCampaign';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  taggingDatasets: Array<TaggingDataset>;
  updatedAt: Scalars['DateTime'];
};

export type TaggingCampaignNotFoundError = NotFoundError | TaggingCampaign;

export type TaggingDataset = {
  __typename: 'TaggingDataset';
  availableTags: Array<Tag>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  processedAssets: Scalars['Int'];
  totalAssets: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type User = {
  __typename: 'User';
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  roles: Array<Role>;
};

export type AssignedAssetQueryVariables = Exact<{
  taggingDatasetId: Scalars['String'];
}>;


export type AssignedAssetQuery = { __typename: 'Query', assignedAsset: { __typename: 'AssignedAsset', assetPath: string, assetType: string, groupId: string, commonTagIds: Array<string>, availableTags: Array<{ __typename: 'Tag', id: string, name: string }> } | { __typename: 'NotFoundError' } };

export type EditAssetMutationVariables = Exact<{
  taggingDatasetId: Scalars['String'];
  groupId: Scalars['String'];
  tagIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type EditAssetMutation = { __typename: 'Mutation', editProcessedAsset: { __typename: 'ProcessedAsset', groupId: string, tags: Array<{ __typename: 'Tag', id: string, name: string }> } };

export type ProcessAssetMutationVariables = Exact<{
  taggingDatasetId: Scalars['String'];
  groupId: Scalars['String'];
  tagIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type ProcessAssetMutation = { __typename: 'Mutation', processAssignedAsset: { __typename: 'AssignedAsset', groupId: string } };

export type ProcessedAssetsQueryVariables = Exact<{
  taggingDatasetId: Scalars['String'];
  tagIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type ProcessedAssetsQuery = { __typename: 'Query', processedAssets: Array<{ __typename: 'ProcessedAsset', assetPath: string, assetType: string, groupId: string, tags: Array<{ __typename: 'Tag', id: string, name: string }>, availableTags: Array<{ __typename: 'Tag', id: string, name: string }> }> };

export type TaggingCampaingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TaggingCampaingsQuery = { __typename: 'Query', taggingCampaings: Array<{ __typename: 'NotFoundError' } | { __typename: 'TaggingCampaign', name: string, id: string, taggingDatasets: Array<{ __typename: 'TaggingDataset', id: string, name: string, totalAssets: number, processedAssets: number, availableTags: Array<{ __typename: 'Tag', id: string, name: string }> }> }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename: 'Query', user: { __typename: 'User', email: string, fullName: string, roles: Array<Role> } };


export const AssignedAssetDocument = gql`
    query assignedAsset($taggingDatasetId: String!) {
  assignedAsset(taggingDatasetId: $taggingDatasetId) {
    ... on AssignedAsset {
      assetPath
      assetType
      groupId
      availableTags {
        id
        name
      }
      commonTagIds
    }
  }
}
    `;

/**
 * __useAssignedAssetQuery__
 *
 * To run a query within a React component, call `useAssignedAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedAssetQuery({
 *   variables: {
 *      taggingDatasetId: // value for 'taggingDatasetId'
 *   },
 * });
 */
export function useAssignedAssetQuery(baseOptions: Apollo.QueryHookOptions<AssignedAssetQuery, AssignedAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignedAssetQuery, AssignedAssetQueryVariables>(AssignedAssetDocument, options);
      }
export function useAssignedAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignedAssetQuery, AssignedAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignedAssetQuery, AssignedAssetQueryVariables>(AssignedAssetDocument, options);
        }
export type AssignedAssetQueryHookResult = ReturnType<typeof useAssignedAssetQuery>;
export type AssignedAssetLazyQueryHookResult = ReturnType<typeof useAssignedAssetLazyQuery>;
export type AssignedAssetQueryResult = Apollo.QueryResult<AssignedAssetQuery, AssignedAssetQueryVariables>;
export const EditAssetDocument = gql`
    mutation EditAsset($taggingDatasetId: String!, $groupId: String!, $tagIds: [String!]!) {
  editProcessedAsset(
    taggingDatasetId: $taggingDatasetId
    groupId: $groupId
    tagIds: $tagIds
  ) {
    groupId
    tags {
      id
      name
    }
  }
}
    `;
export type EditAssetMutationFn = Apollo.MutationFunction<EditAssetMutation, EditAssetMutationVariables>;

/**
 * __useEditAssetMutation__
 *
 * To run a mutation, you first call `useEditAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssetMutation, { data, loading, error }] = useEditAssetMutation({
 *   variables: {
 *      taggingDatasetId: // value for 'taggingDatasetId'
 *      groupId: // value for 'groupId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useEditAssetMutation(baseOptions?: Apollo.MutationHookOptions<EditAssetMutation, EditAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAssetMutation, EditAssetMutationVariables>(EditAssetDocument, options);
      }
export type EditAssetMutationHookResult = ReturnType<typeof useEditAssetMutation>;
export type EditAssetMutationResult = Apollo.MutationResult<EditAssetMutation>;
export type EditAssetMutationOptions = Apollo.BaseMutationOptions<EditAssetMutation, EditAssetMutationVariables>;
export const ProcessAssetDocument = gql`
    mutation ProcessAsset($taggingDatasetId: String!, $groupId: String!, $tagIds: [String!]!) {
  processAssignedAsset(
    taggingDatasetId: $taggingDatasetId
    groupId: $groupId
    tagIds: $tagIds
  ) {
    groupId
  }
}
    `;
export type ProcessAssetMutationFn = Apollo.MutationFunction<ProcessAssetMutation, ProcessAssetMutationVariables>;

/**
 * __useProcessAssetMutation__
 *
 * To run a mutation, you first call `useProcessAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processAssetMutation, { data, loading, error }] = useProcessAssetMutation({
 *   variables: {
 *      taggingDatasetId: // value for 'taggingDatasetId'
 *      groupId: // value for 'groupId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useProcessAssetMutation(baseOptions?: Apollo.MutationHookOptions<ProcessAssetMutation, ProcessAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessAssetMutation, ProcessAssetMutationVariables>(ProcessAssetDocument, options);
      }
export type ProcessAssetMutationHookResult = ReturnType<typeof useProcessAssetMutation>;
export type ProcessAssetMutationResult = Apollo.MutationResult<ProcessAssetMutation>;
export type ProcessAssetMutationOptions = Apollo.BaseMutationOptions<ProcessAssetMutation, ProcessAssetMutationVariables>;
export const ProcessedAssetsDocument = gql`
    query ProcessedAssets($taggingDatasetId: String!, $tagIds: [String!], $limit: Int!, $offset: Int) {
  processedAssets(
    taggingDatasetId: $taggingDatasetId
    tagIds: $tagIds
    limit: $limit
    offset: $offset
  ) {
    assetPath
    assetType
    groupId
    tags {
      id
      name
    }
    availableTags {
      id
      name
    }
  }
}
    `;

/**
 * __useProcessedAssetsQuery__
 *
 * To run a query within a React component, call `useProcessedAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessedAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessedAssetsQuery({
 *   variables: {
 *      taggingDatasetId: // value for 'taggingDatasetId'
 *      tagIds: // value for 'tagIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useProcessedAssetsQuery(baseOptions: Apollo.QueryHookOptions<ProcessedAssetsQuery, ProcessedAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessedAssetsQuery, ProcessedAssetsQueryVariables>(ProcessedAssetsDocument, options);
      }
export function useProcessedAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessedAssetsQuery, ProcessedAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessedAssetsQuery, ProcessedAssetsQueryVariables>(ProcessedAssetsDocument, options);
        }
export type ProcessedAssetsQueryHookResult = ReturnType<typeof useProcessedAssetsQuery>;
export type ProcessedAssetsLazyQueryHookResult = ReturnType<typeof useProcessedAssetsLazyQuery>;
export type ProcessedAssetsQueryResult = Apollo.QueryResult<ProcessedAssetsQuery, ProcessedAssetsQueryVariables>;
export const TaggingCampaingsDocument = gql`
    query taggingCampaings {
  taggingCampaings {
    ... on TaggingCampaign {
      name
      id
      taggingDatasets {
        id
        name
        totalAssets
        processedAssets
        availableTags {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTaggingCampaingsQuery__
 *
 * To run a query within a React component, call `useTaggingCampaingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaggingCampaingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaggingCampaingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaggingCampaingsQuery(baseOptions?: Apollo.QueryHookOptions<TaggingCampaingsQuery, TaggingCampaingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaggingCampaingsQuery, TaggingCampaingsQueryVariables>(TaggingCampaingsDocument, options);
      }
export function useTaggingCampaingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaggingCampaingsQuery, TaggingCampaingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaggingCampaingsQuery, TaggingCampaingsQueryVariables>(TaggingCampaingsDocument, options);
        }
export type TaggingCampaingsQueryHookResult = ReturnType<typeof useTaggingCampaingsQuery>;
export type TaggingCampaingsLazyQueryHookResult = ReturnType<typeof useTaggingCampaingsLazyQuery>;
export type TaggingCampaingsQueryResult = Apollo.QueryResult<TaggingCampaingsQuery, TaggingCampaingsQueryVariables>;
export const UserDocument = gql`
    query User {
  user {
    email
    fullName
    roles
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;